<template>
  <div id="failReason">
    <van-radio-group v-model="radio" checked-color="#EE0A24" @change="selectReason()">
      <van-cell-group>
        <span class="resultTitle">失败原因</span>
        <div class="van-hairline--bottom"></div>
        <van-cell :title="item" clickable @click="radio = item" v-for="(item,index) in failReasonList" :key="index">
          <template #right-icon>
            <van-radio :name="item" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-field v-model="message" rows="2" autosize label="留言" type="textarea" maxlength="50" placeholder="请输入其他原因" show-word-limit v-if="messageShow" @input="changeMessage" />
    </van-radio-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: "0",
      message: "",
      messageShow: false,
      failReasonList: [
        "客户电话打不通或者无人接听",
        "客户无法确认时间，需要再次确认",
        "客户已不需要服务",
        "客户表示未到货",
        "装修周期未到",
        "客户电话有误(电话接通，客户说打错了)",
        "其他原因",
      ],
    };
  },
  methods: {
    selectReason(index) {
      console.log(this.radio, this.failReasonList[this.radio]);
      if (this.radio == "其他原因") {
        this.messageShow = true;
      } else {
        this.messageShow = false;
      }
      this.$emit("getReason", this.radio);
    },
    changeMessage() {
      this.$emit("getMessage", this.message);
    },
  },
};
</script>
<style lang="less" scoped>
#failReason {
  margin-bottom: 50px;
  .resultTitle {
    font-size: 1.6rem;
    padding: 1.2rem 0;
    display: block;
    text-align: center;
  }
}
</style>