<template>
  <!-- 预约客户 -->
  <loading :type=2 v-if="loading" />
  <div id="reservationUser" v-else>
    <van-nav-bar title="预约客户" left-arrow @click-left="toLastIndex" :border="false" />
    <UserContact :userInfo="userInfo" />
    <SelectResult :selectStatusList="selectStatusList" @getSelectResult="getSelectResult" @getshowType="getshowType" :theme="theme" />
    <SelectTime v-if="showType.successShow" @getVisitTime="getVisitTime" :selectTimeDate="selectTimeDate" />
    <FailReason v-if="showType.failShow" @getReason="getReason" @getMessage="getMessage" :style="{paddingBottom: '10rem'}" />
    <div style="margin-top: 2rem">
      <div id="positionBottom">
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitReservation">
          提交
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import UserContact from "@/components/order/UserContact.vue";
import SelectTime from "@/components/effect/SelectTime.vue";
import FailReason from "@/components/order/FailReason.vue";
import SelectResult from "@/components/effect/SelectResult.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      loading: true,
      timeSelectShow: false,
      activeKey: 0,
      radio: 1,
      actTemp: -1,
      showType: {},
      selectStatusList: [
        {
          status: ["预约成功"],
          contact_status: 1,
        },
        {
          status: ["预约失败"],
          contact_status: 2,
        },
      ],
      theme: {
        title: "预约结果",
        viceTitle: "",
      },
      selectResult: {},
      contact_status: 3,
      visit_time: "",
      reason: "",
      else_reason: "",
      userInfo: {},
      selectTimeDate: {
        title: "预约时间",
        event: "submitTimer",
        buttonTitle: "确定时间",
        haveVaule: "选择预约时间",
      },
    };
  },
  created() {
    this.$http
      .get(`/api/v2/worker/order/${this.$route.params.orderId}/appoint`)
      .then((res) => {
        this.userInfo = res.data;
        this.loading = false;
      });
  },
  components: {
    UserContact,
    SelectTime,
    FailReason,
    SelectResult,
  },
  methods: {
    getshowType(e) {
      this.showType = e;
    },
    getSelectResult(e) {
      this.selectResult = e;
      console.log(this.selectResult);
    },
    timeSelect() {
      this.timeSelectShow = true;
    },
    toLastIndex() {
      this.$router.go(-1);
    },
    submitReservation() {
      switch (this.selectResult.contact_status) {
        case 1:
          if (this.visit_time.length != 0) {
            this.$http
              .post(
                `/api/v2/worker/order/${this.$route.params.orderId}/reservation`,
                {
                  contact_status: this.selectResult.contact_status,
                  visit_time: this.visit_time,
                }
              )
              .then((res) => {
                Toast.success("提交成功");
                this.goSubPageR("order");
              })
              .catch((err) => {
                Toast(err.error);
              });
          } else {
            Toast("请选择预约时间");
          }
          break;
        case 2:
          if (this.reason == 7 && this.else_reason.length == 0) {
            Toast("请填写其他原因");
          } else {
            this.$http
              .post(
                `/api/v2/worker/order/${this.$route.params.orderId}/reservation`,
                {
                  order_id: this.$route.params.orderId,
                  contact_status: this.selectResult.contact_status,
                  reason: this.reason,
                  else_reason: this.else_reason,
                }
              )
              .then((res) => {
                Toast.success("提交成功");
                this.goSubPage("order");
              })
              .catch((err) => {
                console.log(err);
                Toast.fail(err.error);
              });
          }
          break;
        default:
          Toast("请选择预约结果");
      }
    },
    getVisitTime(e) {
      console.log(e);
      this.visit_time = e;
    },
    getReason(e) {
      this.reason = e + 1;
      console.log(this.reason);
    },
    getMessage(e) {
      this.else_reason = e;
    },
  },
};
</script>
<style lang="less">
#reservationUser {
  #positionBottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
  }
  .van-nav-bar__text {
    color: #9697b2;
  }
  .topBarBac {
    background: @themeColor !important;
    color: #fff;
  }

  .resultWrap {
    margin: 1.8rem 0;
    background: #fff;
    text-align: center;
    // color: @themeFontColor;
    .resultTitle {
      font-size: 1.6rem;
      padding: 1.2rem 0;
      display: block;
    }
    .reservationResult {
      width: 100%;
      height: 8rem;
      background: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .resultSelect {
        height: 5rem;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        font-size: 1.6rem;
        border-radius: 6px;
        color: #c2c2c2;
        &.act {
          color: @themeColor;
          border: 1px solid @themeColor;
          font-weight: 600;
          position: relative;
          overflow: hidden;
        }
      }
      .act:after {
        content: "";
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 50px;
        position: absolute;
        right: 2px;
        top: 0;
      }
    }
  }
  .timeSelect {
    margin: 0.5rem 0;
    .custom-title {
      margin-right: 1rem;
    }
    van-popup van-popup--bottom {
      overflow: hidden;
    }
    .van-nav-bar {
      border-bottom: 1px solid #f7f8fa;
      height: 5rem;
    }
    .prpupWrap {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .left {
        .van-sidebar {
          height: 30rem;
          width: 100px;
          .van-sidebar-item {
            white-space: nowrap;
          }
        }
      }
      .right {
        height: 30rem;
        flex: 1;
        overflow: auto;
      }
    }
  }
}
</style>