<template>
  <!-- 用户联系卡片 -->
  <div id="userContact">
    <div class="avatar">
      <svg t="1603684318615" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12053" width="48" height="48">
        <path d="M0.00001 512.077A511.923 511.923 0 1 0 511.92301 0 511.974 511.974 0 0 0 0.00001 512.077z" fill="#FFFFFF" p-id="12054"></path>
        <path d="M887.49001 857.89c-13.697-71.82-139.895-140.459-253.165-177.96-5.54-1.846-40.014-17.339-18.417-82.798 56.43-57.815 99.214-150.924 99.214-242.597 0-140.82-93.827-214.742-202.891-214.742s-202.635 73.82-202.635 214.742c0 91.98 42.784 185.45 99.317 243.162 22.059 57.712-17.34 79.207-25.65 82.08-107.73 38.834-232.903 107.73-246.702 177.96a511.307 511.307 0 1 1 887.49-346.635 507.87 507.87 0 0 1-136.56 346.788" fill="#A0B4DC" p-id="12055" data-spm-anchor-id="a313x.7781069.0.i7" class="selected"></path>
      </svg>
    </div>
    <div class="namePhone">
      <div v-if="userInfo.order == undefined">
        <p>
          <van-skeleton title />
        </p>
        <p>
          <van-skeleton title />
        </p>
      </div>
      <div v-else>
        <p> 客户: {{userInfo.order.client_name}}</p>
        <p> 电话: {{userInfo.order.client_mobile}}</p>
      </div>
    </div>
    <div class="call" v-if="userInfo.order.client_mobile !== undefined">
      <a :href="`tel:${userInfo.order.client_mobile}`">
        <svg t="1603684841202" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28876" width="30" height="30">
          <path d="M706.709504 923.947008c-53.997568 0-124.09856-36.25984-173.268992-66.885632-66.076672-40.308736-136.998912-96.698368-200.660992-157.94176l-20.14208-20.160512c-61.247488-63.660032-117.661696-134.600704-157.960192-200.671232-30.623744-49.156096-66.881536-119.273472-66.881536-173.2608 0-58.025984 41.908224-120.076288 59.635712-144.257024 13.70112-18.532352 62.857216-78.974976 103.960576-78.974976 16.922624 0 35.454976 11.286528 59.63776 36.25984 20.946944 21.766144 41.089024 49.168384 54.790144 68.5056 17.727488 25.792512 34.664448 52.3776 46.739456 75.753472 20.158464 37.064704 21.766144 53.194752 21.766144 62.050304 0 17.727488-8.871936 33.845248-27.40224 45.93664-12.091392 8.871936-26.595328 14.505984-40.298496 20.959232-9.660416 4.026368-24.971264 10.469376-30.623744 15.314944 2.41664 8.050688 10.481664 29.011968 34.6624 63.660032 21.753856 32.231424 50.765824 67.684352 76.556288 94.285824 26.585088 25.792512 61.247488 53.999616 94.271488 76.576768 34.676736 23.351296 55.607296 32.23552 63.690752 34.650112 4.829184-5.64224 11.272192-20.965376 15.29856-30.625792 5.636096-13.71136 12.075008-28.207104 20.959232-40.312832 12.075008-18.520064 27.40224-28.207104 45.922304-28.207104 8.884224 0 24.182784 2.439168 62.050304 22.571008 23.379968 12.10368 49.975296 29.007872 75.769856 46.75584 19.324928 12.875776 46.723072 33.837056 68.487168 54.800384 24.987648 24.15616 36.25984 42.7008 36.25984 59.635712 0 41.091072-61.241344 90.261504-79.75936 103.950336C826.791936 882.051072 764.737536 923.947008 706.709504 923.947008L706.709504 923.947008 706.709504 923.947008z" p-id="28877" fill="#FF7963"></path>
          <path d="M664.014848 484.74112c-8.884224 0-16.93696-5.648384-18.546688-14.505984-4.028416-25.792512-18.550784-47.546368-41.089024-62.050304-22.573056-15.310848-49.975296-20.156416-77.379584-15.310848-9.660416 1.609728-19.335168-4.845568-20.946944-15.312896-1.622016-9.6768 4.831232-19.351552 14.505984-20.959232 37.064704-6.443008 74.12736 0.804864 103.944192 20.959232 30.625792 20.14208 50.784256 50.765824 57.22112 86.2208 1.609728 9.6768-4.831232 19.351552-14.493696 20.959232C665.624576 484.74112 664.817664 484.74112 664.014848 484.74112L664.014848 484.74112z" p-id="28878" fill="#FF7963"></path>
          <path d="M773.595136 438.001664c-8.8576 0-16.910336-6.455296-18.518016-15.312896-8.07936-49.168384-37.091328-91.062272-79.794176-120.07424-42.727424-28.209152-95.895552-38.688768-148.28544-29.81888-9.662464 2.414592-19.335168-4.84352-20.946944-14.505984-1.622016-9.6768 4.831232-19.351552 14.508032-20.96128 62.052352-11.272192 124.104704 1.609728 174.87872 35.467264 51.560448 33.841152 85.405696 84.609024 95.8976 143.439872 1.60768 10.479616-4.831232 19.349504-14.524416 21.766144C775.206912 438.001664 774.4 438.001664 773.595136 438.001664L773.595136 438.001664z" p-id="28879" fill="#FF7963"></path>
          <path d="M919.461888 424.302592c-8.050688 0-16.130048-6.457344-17.737728-15.312896-14.4896-83.818496-62.857216-156.350464-136.18176-203.894784-73.345024-48.351232-162.801664-66.883584-251.424768-50.767872-10.496 1.613824-19.351552-4.829184-21.768192-15.310848-1.609728-9.6768 5.638144-19.337216 15.312896-20.96128 98.322432-16.922624 196.632576 2.428928 278.034432 56.416256 81.375232 53.999616 135.380992 134.58432 152.30976 228.868096 1.613824 9.674752-4.85376 19.349504-15.316992 20.96128C921.882624 424.302592 921.07776 424.302592 919.461888 424.302592L919.461888 424.302592z" p-id="28880" fill="#FF7963"></path>
        </svg>
      </a>
    </div>
    <div class="call" v-else>
      <a href="tel18721258989">
        <svg t="1603684841202" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28876" width="30" height="30">
          <path d="M706.709504 923.947008c-53.997568 0-124.09856-36.25984-173.268992-66.885632-66.076672-40.308736-136.998912-96.698368-200.660992-157.94176l-20.14208-20.160512c-61.247488-63.660032-117.661696-134.600704-157.960192-200.671232-30.623744-49.156096-66.881536-119.273472-66.881536-173.2608 0-58.025984 41.908224-120.076288 59.635712-144.257024 13.70112-18.532352 62.857216-78.974976 103.960576-78.974976 16.922624 0 35.454976 11.286528 59.63776 36.25984 20.946944 21.766144 41.089024 49.168384 54.790144 68.5056 17.727488 25.792512 34.664448 52.3776 46.739456 75.753472 20.158464 37.064704 21.766144 53.194752 21.766144 62.050304 0 17.727488-8.871936 33.845248-27.40224 45.93664-12.091392 8.871936-26.595328 14.505984-40.298496 20.959232-9.660416 4.026368-24.971264 10.469376-30.623744 15.314944 2.41664 8.050688 10.481664 29.011968 34.6624 63.660032 21.753856 32.231424 50.765824 67.684352 76.556288 94.285824 26.585088 25.792512 61.247488 53.999616 94.271488 76.576768 34.676736 23.351296 55.607296 32.23552 63.690752 34.650112 4.829184-5.64224 11.272192-20.965376 15.29856-30.625792 5.636096-13.71136 12.075008-28.207104 20.959232-40.312832 12.075008-18.520064 27.40224-28.207104 45.922304-28.207104 8.884224 0 24.182784 2.439168 62.050304 22.571008 23.379968 12.10368 49.975296 29.007872 75.769856 46.75584 19.324928 12.875776 46.723072 33.837056 68.487168 54.800384 24.987648 24.15616 36.25984 42.7008 36.25984 59.635712 0 41.091072-61.241344 90.261504-79.75936 103.950336C826.791936 882.051072 764.737536 923.947008 706.709504 923.947008L706.709504 923.947008 706.709504 923.947008z" p-id="28877" fill="#FF7963"></path>
          <path d="M664.014848 484.74112c-8.884224 0-16.93696-5.648384-18.546688-14.505984-4.028416-25.792512-18.550784-47.546368-41.089024-62.050304-22.573056-15.310848-49.975296-20.156416-77.379584-15.310848-9.660416 1.609728-19.335168-4.845568-20.946944-15.312896-1.622016-9.6768 4.831232-19.351552 14.505984-20.959232 37.064704-6.443008 74.12736 0.804864 103.944192 20.959232 30.625792 20.14208 50.784256 50.765824 57.22112 86.2208 1.609728 9.6768-4.831232 19.351552-14.493696 20.959232C665.624576 484.74112 664.817664 484.74112 664.014848 484.74112L664.014848 484.74112z" p-id="28878" fill="#FF7963"></path>
          <path d="M773.595136 438.001664c-8.8576 0-16.910336-6.455296-18.518016-15.312896-8.07936-49.168384-37.091328-91.062272-79.794176-120.07424-42.727424-28.209152-95.895552-38.688768-148.28544-29.81888-9.662464 2.414592-19.335168-4.84352-20.946944-14.505984-1.622016-9.6768 4.831232-19.351552 14.508032-20.96128 62.052352-11.272192 124.104704 1.609728 174.87872 35.467264 51.560448 33.841152 85.405696 84.609024 95.8976 143.439872 1.60768 10.479616-4.831232 19.349504-14.524416 21.766144C775.206912 438.001664 774.4 438.001664 773.595136 438.001664L773.595136 438.001664z" p-id="28879" fill="#FF7963"></path>
          <path d="M919.461888 424.302592c-8.050688 0-16.130048-6.457344-17.737728-15.312896-14.4896-83.818496-62.857216-156.350464-136.18176-203.894784-73.345024-48.351232-162.801664-66.883584-251.424768-50.767872-10.496 1.613824-19.351552-4.829184-21.768192-15.310848-1.609728-9.6768 5.638144-19.337216 15.312896-20.96128 98.322432-16.922624 196.632576 2.428928 278.034432 56.416256 81.375232 53.999616 135.380992 134.58432 152.30976 228.868096 1.613824 9.674752-4.85376 19.349504-15.316992 20.96128C921.882624 424.302592 921.07776 424.302592 919.461888 424.302592L919.461888 424.302592z" p-id="28880" fill="#FF7963"></path>
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    console.log(this.userInfo);
  },
};
</script>
<style lang="less">
#userContact {
  margin: 2rem auto;
  box-sizing: border-box;
  padding: 0 1rem;
  width: 95%;
  height: 8rem;
  background: #fff;
  box-shadow: 0 0 8px 0px #fff;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 10px;
  .avatar,
  .call {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .namePhone {
    flex: 1;
    margin-left: 1.5rem;
    p {
      padding: 0.4rem;
      margin-left: 1rem;
    }
  }
  .call {
    width: 20%;
  }
}
</style>